import 'webp-in-css/polyfill';
import { scrollToElement } from './utils';
import { scrollAnimate } from './utils';
import features from './templates/features/features';
import { init as initSentry } from '../common/Sentry';

const header = document.querySelector('header');
const switchLangBtn = header.querySelector('.button-switch-lang');
const loginButton = header.querySelector('.login_button');
const disableToScrollAnchors = ['privacy-policy', 'error-modal', 'success-modal', 'video-modal'];

const languageCheckerEnUrl = process.env.NODE_ENV === 'prod' ? 'https://talentrocks.io' : 'https://test.talentrocks.io';

const languageCheckerRuUrl = process.env.NODE_ENV === 'prod' ? 'https://talentrocks.ru' : 'https://test.talentrocks.ru';

const enLink = document.getElementById('link-en');
if (enLink) {
  enLink.setAttribute('href', languageCheckerEnUrl);
}
const ruLink = document.getElementById('link-ru');
if (ruLink) {
  ruLink.setAttribute('href', languageCheckerRuUrl);
}

window.onscroll = function () {
  if (document.body.scrollTop >= 30 || document.documentElement.scrollTop >= 30) {
    header.classList.add('colored', 'fixed');
    header.classList.remove('transparent');
    if (switchLangBtn) {
      switchLangBtn.classList.remove('button--white');
      switchLangBtn.classList.add('button--grey');
    }
    if (loginButton) {
      loginButton.classList.remove('button--white');
      loginButton.classList.add('button--grey');
    }
  } else {
    header.classList.add('transparent');
    header.classList.remove('colored', 'fixed');
    if (switchLangBtn) {
      switchLangBtn.classList.add('button--white');
      switchLangBtn.classList.remove('button--grey');
    }
    if (loginButton) {
      loginButton.classList.add('button--white');
      loginButton.classList.remove('button--grey');
    }
  }
};

const inputs = document.querySelectorAll('input');

function validate(e) {
  e.preventDefault();
  e.target.parentNode.classList.add('error');
}

inputs.forEach((input) => {
  input.addEventListener('invalid', validate);
});

function isValidUrl(url) {
  const regexp = /^(ftp|http|https):\/\/[^ "]+$/;
  return regexp.test(url);
}

const anchors = document.querySelectorAll('a[href^="#"]');
for (let anchor of anchors) {
  anchor.addEventListener('click', (e) => {
    const elementId = anchor.getAttribute('href').substr(1);
    if (!disableToScrollAnchors.includes(elementId)) {
      e.preventDefault();
      scrollToElement(anchor.getAttribute('href').substr(1));
    }
  });
}

scrollAnimate().init();
features();

initSentry('ru');
